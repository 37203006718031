/* styles for the app */

/* 
  retro colors: https://www.schemecolor.com/retro-vintage.php 
*/

:root {

    --main-bg-color: #E2D7A7;
    --primary-color: #A16B56;
    --primary-dark: #6f4a3b;
    --light-accent: #E0A370;
    --dark-accent: #567D89;
    --highlight: #DEC584;

    --rb-top: #c42a31;
    --rb-side: #831c21;
    --rb-pressed-top: #e90510;
    --rb-pressed-side: #af040c;

    --cb-top: #E2D7A7;
    --cb-side: #85752c;
    --cb-pressed-top: #e8e0ba;
    --cb-pressed-side: rgba(0, 0, 0, .85);

}

/* === HTML ===


*/

@font-face {
    font-family: 'Jua';
    src: local('MyFont'), url(../assets/fonts/Jua-Regular.ttf) format('truetype');
}

html, body {
    height: 100%;
    width: 100vw;
}



/* ===== App Layout ======



*/

.App {
    background-color: var(--main-bg-color);

    height: 100vh;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.box-body {
    /* shape */
    min-height: 600px;
    height: 100%;
    max-height: 1000px;
    
    min-width: 350px;
    width: 100%;
    max-width: 600px;

    margin: 5vh 56vw;
    padding: 40px;

    /* color */
    background-color: var(--primary-color);
    border-radius: 20px;
    box-shadow: 20px 20px 0px var(--primary-dark),
        20px 20px 64px rgba(0, 0, 0, .30);
    /* contents */
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
}

.layout-section {
    width: 100%;
    background-color: var(--primary-color);
}

.info-box {
    background-color: var(--dark-accent);
    min-height: 50%;
    border-radius: 5%;
    height: 60%;

    box-shadow: inset 0 0 32px rgba(0, 0, 0, .15),
        inset 8px 8px 0px var(--primary-dark);
    padding: 20px 10px 10px 20px;

    overflow-y: auto;
}

.display-box {
    min-height: 15%;
    max-height: 15%;
    border-radius: 10px;
    margin: 20px 0;
    padding: 16px 24px 8px 40px;

    box-shadow: inset 0 0 64px rgba(0, 0, 0, .3),
        inset 8px 8px 0px var(--primary-dark);
}

.button-box {
    background-color: var(--primary-color);
    height: 20%;
    max-height: 190px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.control-button-box {
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.settings-box {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 10%;
}

.widget-box {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 10%;
}


@media only screen and (max-width: 600px) {
    .box-body {
        width: 100vw;

        padding-left: 7vw;
        padding-right: 7vw;

    }
}


@media only screen and (max-height: 500px) {
    .box-body {
        height: 100vh;
        /* 
        padding-bottom: 7vh;
        padding-top: 7vw; */
    }

    .display-section {
        max-height: 0%;
        height: 0%;
    }
}


/* ===== General Components ======



*/

.alert {
    display: inline-block;
    max-width: 550px;
    width: 100%;
}

.alert-text {
    color: red;
}

.loading-display {
    text-align: center;
}

.inactive-display {
    text-align: center;
}

.hidden {
    display: none;
}

/* ===== Visual Section =====


*/
.waveform {
    height: 100%;
    width: 100%;
}


/* ===== App Components ======


*/
.recording-box {
    width: 20vw;
    height: 100%;

    max-width: 15vh;
    min-width: 150px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    background-color: var(--light-accent);

    box-shadow: inset 0 0 16px rgba(0, 0, 0, .10),
        inset 8px 8px 0px var(--primary-dark);

}

.recording-button {
    /* border-radius: 50%; */
    width: 120px;
    height: 120px;
    border: none;
    margin-bottom: 16px;

    background: var(--rb-top);
    text-shadow: 0 3px 1px rgba(122, 17, 8, .8);
    box-shadow: 0px 16px 0px var(--rb-side),
        0 15px 20px rgba(0, 0, 0, .35);

    transition: .08s all ease-in;
    outline: none;

    cursor: pointer;

    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.recording-button span {
    color: black;
    text-align: center;
}

.rb-pressed {
    padding-top: 3px;
    transform: translateY(8px);
    background: var(--rb-pressed-top);
    box-shadow: 0 8px 0 var(--rb-pressed-side),
        0 8px 6px rgba(0, 0, 0, .45);
}

.control-button {
    width: 100%;
    max-width: 150px;
    min-width: 45px;
    height: 100%;
    border: none;

    background: var(--cb-top);

    box-shadow: inset 2px 0 0 rgba(0, 0, 0, .75),
        /* border */
        inset 8px -8px 16px var(--cb-pressed-top),
        /* inner shadow */
        0px 4px 2px rgba(0, 0, 0, .25)
        /* top outer shadow */
    ;

    transition: .02s all ease-in;
    outline: none;

    cursor: pointer;

    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.cb-pressed {
    padding-top: 3px;
    transform: translateY(4px);
    background: var(--cb-pressed-top);
    box-shadow: inset 8px -8px 16px rgba(0, 0, 0, .20),
        inset -4px 4px 0px var(--cb-pressed-side),
        inset 4px -4px 0 var(--cb-pressed-side),
        0 -4px 2px rgba(0, 0, 0, .25);
}

.cb-icon {
    /* width:100px; */
}


/* ======= Dashboard Components =======



*/

.timeDomGraph {
    flex: 1;
    height: 100%;
    width: 100%;
}

.simple-component {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    text-align: center;

    height: 100%;
    padding: 20px;
}

.dash-header {
    /* padding: 12px; */
}

.tuner {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    text-align: center;

    height: 100%;
    width: 100%;
    /* padding: 20px; */
    color: var(--cb-top);
}

.tuner-header {
    /* padding: 0px 0px 15px 0px; */
    font-size: calc(42px + 1vmin);
}

.tuner-notes {
    font-size: 32px;
    padding: 20px;
}

.tuner-you {}

.tuner-target {
    color: var(--light-accent);
    font-weight: 500;
    /* padding: 0 1px; */
}

.tuner-bar {
    min-height: 120px;
    width: 12px;
    border-radius: 6px;
    background-color: var(--cb-top);
    /* transition-duration: 1; */
    transform-origin: bottom;
}

.tonal-display {
    text-align: center;
    overflow-wrap: anywhere;
}

.current-chord {
    font-size: 36px;
}

/* ======== Midi Mouth ======= 



*/

.midi-app {
    height: 100vh;
    width: 100%;
    /* ensures app takes up all of screen */
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;

}

.midi-main {
    height: 100vh;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-center;
    align-items: center;
    padding: 20px;
    flex: 1;
}

.midi-header {
    margin-bottom: 20px;
}

.midi-form {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: fit-content;
}

.midi-form-label {
    font-size: 20px;
    margin-bottom: -5px;
}

.midi-advanced-options {
    padding: 20px;
}